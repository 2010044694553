<template>
  <div :class="{ 'is-loading': generating }">
    <CCard>
      <CCardHeader>
        <h2>Rapid Insight Generator</h2>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol col="3">
            <CSelect
              :options="taxEngines"
              :value.sync="taxEngine"
              placeholder="Choose"
              label="Tax Engine:"
              horizontal
            />
          </CCol>
        </CRow>
        <FileUploadBlock
          :file="currentFile"
          :accepted-types="acceptedTypes"
          @update="setCurrentFile"
        >
          <template #footer>
            <CButton
              color="primary"
              class="mr-2"
              :disabled="!currentFile || !taxEngine"
              @click="generate('TEXT')"
            >
              <MIcon name="fa fa-file-text-o" class="mr-2" />As Text
            </CButton>
            <CButton
              color="primary"
              class="mr-2"
              :disabled="!currentFile || !taxEngine"
              @click="generate('EXCEL')"
            >
              <MIcon name="fa fa-file-excel-o" class="mr-2" />As Excel
            </CButton>
            <CButton color="secondary" @click="back">Back</CButton>
          </template>
        </FileUploadBlock>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import API from '@/api';
import FileUploadBlock from '@/components/FileUploadBlock';

const TAX_ENGINES = [
  {
    label: 'Vertex',
    value: 'vtx',
  },
  {
    label: 'OneSource',
    value: 'sbx',
  },
];

export default {
  name: 'TheQuickLookReport',
  components: {
    FileUploadBlock,
  },
  data() {
    return {
      taxEngine: null,
      type: 'EXCEL',
      currentFile: null,
      generating: false,
      acceptedTypes: ['TXT', 'CSV', 'XML', 'ZIP'],
    };
  },
  computed: {
    getFilePreview() {
      if (!this.currentFile) return null;
      // console.log('mime: ' + this.currentFile.type);
      if (this.currentFile.type.startsWith('image/')) {
        return 'file-image';
      }
      switch (this.currentFile.type) {
        case 'application/json':
          return 'file-code';
        case 'application/excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          return 'cil-file-excel';
        case 'text/csv':
          return 'file-csv';
        case 'text/xml':
          return 'file-code';
      }
      return 'file';
    },
    taxEngines() {
      return TAX_ENGINES;
    },
  },
  methods: {
    uploadFile() {
      this.$refs.userFile.click();
    },
    dropFile(evt) {
      let f = evt.dataTransfer.files;
      if (!f) return;
      f.forEach((file) => {
        this.currentFile = file;
      });
    },
    setCurrentFile(f) {
      this.currentFile = f;
    },
    getFilename(disposition) {
      let parts = disposition.split('=');
      return parts[1].replaceAll(/"/gi, '');
    },
    generate(type) {
      this.generating = true;
      API.files
        .generateQuickLook(type, this.currentFile, this.taxEngine)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          const fileName = this.getFilename(
            res['headers']['content-disposition']
          );

          link.href = url;
          link.setAttribute('download', fileName);

          document.body.appendChild(link);

          link.click();
          this.$swal
            .fire({
              icon: 'success',
              title: 'Complete',
              text: 'The Rapid Insight has been generated successfully.',
            })
            .then(() => this.back());
        })
        .catch((err) => {
          let msg = 'Errors occurred communicating with the server.';
          if (err.response && err.response.status === 400) {
            msg =
              'Provided file is invalid or corrupt.  Please check the file and try again.';
          }
          this.$swal
            .fire({
              icon: 'error',
              title: 'Failed',
              text: msg,
            })
            .then(() => this.back());
        })
        .finally(() => {
          this.generating = false;
        });
    },
    back() {
      this.currentFile = null;
      this.$router.push({ name: 'dashboard' });
    },
  },
};
</script>
